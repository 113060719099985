import React from "react"
import styled from "styled-components"
import Image from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import SocialBar from "./socialbar"

const Body = styled.div`
  grid-area: 2 / 1 / 3 / 3;
  /*   background-color: green;
 */
  display: flex;
  flex-flow: column nowrap;
  @media only screen and (min-width: 768px) {
    /* For everything bigger than 768px */
    grid-area: 2/ 2 / 3 / 3;
    overflow: scroll;
  }
`
const TextWrapper = styled.div`
  grid-area: 2 / 1 / 3 / 3;
  padding: 7%;
  @media only screen and (min-width: 768px) {
    /* For everything bigger than 768px */
    grid-area: 2/ 2 / 3 / 3;
    padding-top: 0;
  }
`

const TopText = styled.h1`
  margin-top: 0%;
  font: 700 2em Museo;

  @media only screen and (min-width: 768px) {
    /* For everything bigger than 768px */
  }
`

const BodyText = styled.h3`
  margin-bottom: 10%;
  font: 300 1.5em Museo;
  margin-top: 3%;
  @media only screen and (min-width: 768px) {
    /* For everything bigger than 768px */
    margin-bottom: 0%;
  }
`

const ImgContainer = styled.div`
  margin-top: 7%;
  @media only screen and (min-width: 768px) {
    /* For everything bigger than 768px */
    display: none;
  }
`

const SocialBarWrapper = styled.div`
  margin-bottom: 5%;
  margin-top: 10%;
  @media only screen and (min-width: 768px) {
    /* For everything bigger than 768px */
    display: none;
  }
`

export default () => {
  const data = useStaticQuery(graphql`
    query AboutQuery {
      img1: file(absolutePath: { regex: "/mug-oval.png/" }) {
        childImageSharp {
          fluid(maxWidth: 100, maxHeight: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Body>
      <ImgContainer>
        <div>
          <Image
            fluid={data.img1.childImageSharp.fluid}
            style={{
              maxWidth: "40%",
              margin: "auto",
            }}
          />
        </div>
      </ImgContainer>
      <TextWrapper>
        <TopText>Hi, I’m Raúl,</TopText>
        <BodyText>
          I am a Computer Scientist researching Quantum Computing based in the
          beautiful Munich. I collaborate with the fantastic scientists and
          entrepreneurs of TU Munich to find those use cases that mean a Quantum
          Leap for society. On the side, I share my passion for cutting edge
          technologies by writing this blog & organizing keynotes and workshops
          for the students at CDTM.
        </BodyText>
        <BodyText>
          My career started out as an industrial engineer, diving into the
          fascinating workings of matter. Being raised in an artistic family and
          having lived varied international experiences make me comfortable
          thinking outside, inside, and around the box.
        </BodyText>
        <BodyText>
          I developed a deep interest in entrepreneurship since I started
          trading with guitars when I was sixteen. After founding a couple of
          one-dollar-startups and some rewarding learnings after, I developed a
          good grasp on what are the pitfalls and pillars of company building,
          and to tell the kind of action that drives results.
        </BodyText>
        <BodyText>
          This is just the beginning of a strange and exciting journey. As for
          the reader, always aim for the moonshot, worst case you'll find
          yourself in the sky (:
        </BodyText>
        <SocialBarWrapper>
          <SocialBar style={{ marginTop: "14%" }} />
        </SocialBarWrapper>
      </TextWrapper>
    </Body>
  )
}
